const Menu = [
    { header: 'Nadzorna Plošča' },
    {
        name: 'dashboard',
        title: 'Pregled',
        icon: 'mdi-view-dashboard',
        path: '/pregled',
        group: 'dashboard'
    },
    /*
    {
        name: 'settings',
        title: 'Nastavitve',
        icon: 'mdi-cogs',
        path: '/nastavitve',
        group: 'settings'
    },
    */
    {
        name: 'users_list',
        title: 'Seznam uporabnikov',
        icon: 'mdi-account-group',
        path: '/seznam-uporabnikov',
        group: 'users_list'
    },
    {
        name: 'add_user',
        title: 'Dodaj uporabnika',
        icon: 'mdi-account-plus',
        path: '/dodaj-uporabnika',
        group: 'add_user',
    },
    /*{
        name: 'parking_barriers',
        title: 'Upravljanje zapornic',
        icon: 'mdi-remote',
        path: '/zapornice',
        group: 'parking_barriers',
    },*/

    {
        title: 'Zapornice',
        icon: 'mdi-remote',
        group: 'zapornice', //group naziv mora biti enak kot  začetni path v router ... primer group: uporabniki, path: uporabniki/seznam ---> group: uporabniki == path: uporabniki/...
        permissions: ['ie-parking-barriers'],
        items: [
            {
                name: 'parking_barriers_management',
                title: 'Upravljanje zapornic',
                icon: 'mdi-android-messages',
                path: '/zapornice/upravljanje',
                permissions: ['ie-parking-barriers']
            },
            {
                name: 'parking_barriers_archive',
                title: 'Zgodovina',
                icon: 'mdi-android-messages',
                path: '/zapornice/arhiv',
                permissions: ['ie-parking-barriers'],
            }
        ]
    },
    
]

export { Menu }