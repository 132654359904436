import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/auth/Login.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/prijava',
        name: 'login',
        component: Login
    },
    {
        path: '/ponastavitev-gesla',
        name: 'passwordResetRequest',
        component: () => import('@/views/auth/PasswordResetRequest.vue')
    },
    {
        //path: '/ponastavitev-gesla/:token',
        path: '/password-reset/:token',
        name: 'passwordReset',
        component: () => import('@/views/auth/PasswordReset.vue')
    }, 
    /* ERRORS */
    {
        path: '*',
        name: '404',
        component: () => import('@/views/errors/404.vue')
    },
    {
        path: '/403',
        name: '403',
        component: () => import('@/views/errors/403.vue')
    },
    {
        path: '/500',
        name: '500',
        component: () => import('@/views/errors/500.vue')
    },

    {
        path: '/',
        component: () => import('@/views/RootView.vue'),
        children: [
            {
                path: 'pregled',
                name: 'dashboard' ,
                component: () => import('@/views/Dashboard.vue'),
                alias: '',
                meta: { requiresAuth: true}
            },
            /*
            {
                path: 'nastavitve',
                name: 'settings',
                component: () => import('@/views/Settings.vue'),
                meta: { requiresAuth: true }
            },
            */
            {
                path: 'seznam-uporabnikov',
                name: 'users_list',
                component: () => import('@/views/Users.vue'),
                meta: { requiresAuth: true}
            },
            {
                path: 'dodaj-uporabnika',
                name: 'add_user',
                component: () => import('@/views/AddUser.vue'),
                meta: { requiresAuth: true}
            },
            {
                path: 'seznam-uporabnikov/:id',
                name: 'edit_user',
                component: () => import('@/views/EditUser.vue'),
                meta: { requiresAuth: true}
            },
            /*{
                path:'zapornice',
                name: 'parking_barriers',
                component: () => import('@/views/ParkingBarriers.vue'),
                meta: { requiresAuth: true }
            }*/

            {
                path:'zapornice/upravljanje',
                name: 'parking_barriers_management',
                component: () => import('@/views/ParkingBarriers.vue'),
                meta: { requiresAuth: true }
            },

            {
                path:'zapornice/arhiv',
                name: 'parking_barriers_archive',
                component: () => import('@/views/ParkingBarriersArchive.vue'),
                meta: { requiresAuth: true }
            }
        ]
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: function(to, from, savedPosition) {
        return savedPosition || { x: 0, y: 0 }
    },
    linkActiveClass: 'v-list__tile--active router-link-active active',
    routes
})

export default router