<template>
    <div class="text-xs-center">
      <v-dialog
        v-model="loader"
        persistent
        width="300"
      >
        <v-card
          color="secondary"
          dark
        >
          <v-card-text>
            <div style="padding-top:15px;padding-bottom:5px;color:#fff;">
              {{ loaderText }}
            </div>
            <v-progress-linear
              indeterminate
              color="white"
              height="6"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
  export default {
    name: 'app-loader',
    data: () => ({
      dialog: ''
    }),

    watch: {
    },

    computed: {
      loader: {

        get() {
            return this.$store.getters.loader
        },

        set(newValue) {
            this.$store.commit('SET_LOADER', newValue)
        }
      },

      loaderText() {
        return this.$store.getters.loaderText
      }
    }
  }
</script>